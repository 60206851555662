<template>
  <div class="wx-link padding-20 box-shadow-light-grey border-radius-6">
    <div class="operation padding-bottom-20">
      <div>
        <el-button @click="initData">刷新数据</el-button>
        <el-button
          type="danger"
          plain
          :disabled="selection.length === 0"
          @click="delSelct"
        >批量删除
        </el-button>
        <el-input
          v-model="search"
          placeholder="请输入备注"
          style="width: 200px"
          class="margin-left-twentyFour"
        />
        <select-dept-user :params="params" class="margin-left-twentyFour"/>
        <el-button
          type="primary"
          @click="initData"
          class="margin-left-twentyFour"
        >搜索
        </el-button>
        <el-popover placement="bottom" width="580" trigger="hover">
          <el-table :row-style="{ cursor: 'pointer' }" :data="uselessTempList">
            <el-table-column width="150px" property="name" label="小程序">
            </el-table-column>
            <el-table-column width="200px" property="appid" label="appid">
            </el-table-column>
            <el-table-column
              width="200px"
              property="createdTime"
              label="被封日期"
            ></el-table-column>
          </el-table>
          <el-badge
            slot="reference"
            :value="uselessTempList.length"
            :max="99"
            class="item"
            type="warning"
          >
            <el-button class="custom-button-margin-left" type="warning"
            >被封小程序
            </el-button>
          </el-badge>
        </el-popover>
        <el-tooltip placement="top" effect="light">
          <div slot="content" class="link-tips">
            <h6>公众号关联小程序</h6>
            <p>
              公众号关联小程序后，将可在自定义菜单、模板消息、客服消息等功能中使用小程序。图文消息中可直接使用小程序卡片、链接、图片素材，无需关联小程序。
            </p>
            <h6>关联规则：</h6>
            <p>1.所有公众号都可以关联小程序。</p>
            <p>
              2.公众号可关联10个同主体，3个非同主体小程序。公众号一个月可新增关联小程序13次。
            </p>
            <p>
              3.小程序可设置无需关联确认。设置后，公众号关联小程序不需要小程序确认，单方操作即可关联成功
            </p>
            <p>
              4.小程序可设置需关联确认。设置后，公众号关联小程序需小程序管理员确认后才能关联成功。
            </p>
            <p>5.小程序可设置不允许被关联。设置后，公众号无法关联此小程序。</p>
            <h6>关联流程：</h6>
            <p>登录公众号后台-小程序-小程序管理-添加-关联小程序</p>
          </div>
          <i class="el-icon-question" style="margin-left: 20px"></i>
        </el-tooltip>
      </div>
      <div>
        <el-button type="primary" @click="addMini">新增</el-button>
      </div>
    </div>
    <el-table
      header-row-class-name="table-header"
      class="consume-table"
      style="width: 100%"
      height="660px"
      v-loading="loading"
      empty-text="暂无数据"
      :data="dataList"
      @selection-change="handleSelectChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="ID" prop="id" width="50"></el-table-column>
      <el-table-column label="用户" width="70">
        <template slot-scope="scope">
          <span>{{ scope.row.adminUser.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用" width="70">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isOpen"
            :active-value="1"
            :inactive-value="0"
            @change="handleChangeUsed(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="长链接" min-width="200">
        <template slot-scope="scope">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="line-overflow">{{ scope.row.longUrl }}</div>
            <el-button
              class="button-small"
              @click="
                getCopyLongUrl(`long-url-${scope.row.id}`, scope.row.longUrl)
              "
            >复制
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100" prop="createdTime" label="跳转类型">
        <template slot-scope="scope">
          {{ scope.row.type === 2 ? '图片' : '文章' }}
        </template>
      </el-table-column>
      <el-table-column label="文章/图片链接" min-width="150">
        <template slot-scope="scope">
          <div class="line-overflow">
            <a :href="scope.row.webUrl" target="__blank">{{
                scope.row.webUrl
              }}</a>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="公众号">
        <template slot-scope="scope">
          <div class="line-overflow">
            {{ scope.row.remark }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdTime" label="创建时间"></el-table-column>
      <!-- <el-table-column prop="updatedTime" label="更新时间"> </el-table-column> -->
      <el-table-column prop="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button-small"
            @click="editMini(scope.row)"
          >编辑
          </el-button>
          <el-button
            type="danger"
            class="button-small"
            @click="delMini(scope.row.id)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :title="`${isEdit ? '编辑' : '新增'}`"
      :visible.sync="isShowDialog"
      :before-close="handleClose"
      width="500px"
    >
      <div class="part" style="align-items: flex-start">
        <span>小程序</span>
        <el-checkbox-group
          @change="handleMiniChange"
          v-model="appids"
          style="width: 30px"
        >
          <el-checkbox
            class="mini-check"
            v-for="(item, index) in miniList"
            :key="item.appid"
            :label="item.appid"
          >
            {{ '' }}
          </el-checkbox>
        </el-checkbox-group>
        <div class="title-box">
          <p
            v-for="(item, index) in miniList"
            :key="item.appid"
            :class="handleClass(item) ? 'line_thr' : ''"
          >
            <span style="cursor: pointer" @click="handleAppClick(item)">{{
                item.name
              }}</span>
            <span>{{ '（' + item.appid + '）' }}</span>
          </p>
        </div>
      </div>
      <div class="part">
        <span>模式</span>
        <el-radio-group v-model="type">
          <el-radio :label="1">文章模式</el-radio>
          <!-- <el-radio :label="2">图片模式</el-radio> -->
        </el-radio-group>
      </div>
      <div class="part" v-if="type === 1">
        <span>文章链接</span>
        <el-input
          v-model="webUrl"
          placeholder="文章链接跳转图片任选一种"
          style="width: 300px"
        ></el-input>
      </div>
      <div class="part" v-if="type === 2">
        <span>跳转图片</span>
        <uploadImage @uploadSuccess="handleImgSuccess" :url="imgUrl"/>
      </div>
      <div class="part">
        <span>公众号名</span>
        <el-input
          type="text"
          placeholder="请输入公众号名"
          v-model="remark"
          style="width: 300px"
        ></el-input>
      </div>
      <div class="part" v-if="type === 2">
        <span>关注链接</span>
        <el-input
          type="text"
          placeholder="跳转图片模式必填"
          v-model="subscribeUrl"
          style="width: 300px"
        ></el-input>
      </div>
      <div class="part">
        <span>是否启用</span>
        <el-switch :active-value="1" :inactive-value="0" v-model="isOpen">
        </el-switch>
      </div>
      <div class="button-list">
        <el-button type="" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
    <el-input
      style="display: absolute; left: -9999px"
      id="custom-input"
      v-model="inputVal"
    />
  </div>
</template>
<script>
import uploadImage from '@/components/uploadAvatar'
import SelectDeptUser from '@/components/SelectDeptUser'
import {
  deleteMiniProgram,
  editMiniProgram,
  getMiniprogramInvalid,
  getMiniprogramList,
  getMiniprogramSetting,
  updateMiniProgram,
} from '@/api/novelPut'

export default {
  name: 'WxLink',
  data() {
    return {
      loading: false,
      page: 1,
      total: 0,
      pageSize: 13,
      dataList: [],
      inputVal: '',
      search: '',
      adminUserId: null,
      selection: [],
      isEdit: false,
      isShowDialog: false,
      params: {},
      newParams: {},
      webUrl: '',
      remark: '',
      subscribeUrl: null,
      imgUrl: '',
      id: null,
      isOpen: false,
      appids: [],
      miniList: [],
      type: 1,
      uselessTempList: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    handleMiniChange(value) {
      // 只能选三个小程序
      if (value.length === 4) {
        this.appids = value.splice(0, 3)
      }
    },
    handleAppClick(item) {
      const tmpIndex = this.appids.findIndex((element) => {
        return element === item.appid
      })
      if (tmpIndex === -1) {
        // 清除失效小程序
        const b = this.uselessTempList.some((it) => {
          return item.appid === it.appid
        })
        if (b) {
          this.$message.warning("此小程序已被封!")
        } else {
          this.appids.push(item.appid)
        }
      } else {
        this.appids.splice(tmpIndex, 1)
      }
    },
    handleImgSuccess(url) {
      this.imgUrl = url
    },
    handleChangeUsed(row) {
      editMiniProgram(row.id, {
        webUrl: row.webUrl,
        remark: row.remark,
        userId: row.adminUser.id,
        isOpen: row.isOpen,
      })
        .then(() => {
          this.initData()
        })
        .finally(() => {
          this.handleClose()
        })
    },
    initData() {
      this.isOpen = false
      this.loading = true
      getMiniprogramList({
        search: this.search.trim(),
        page: this.page,
        pageSize: this.pageSize,
        userId: this.params.adminUserId ? this.params.adminUserId : null,
      })
        .then((res) => {
          this.dataList = [...res.list]
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
      getMiniprogramInvalid().then((res) => {
        this.uselessTempList = res
      })
      getMiniprogramSetting().then((res) => {
        this.miniList = res;
      })
    },
    handleClass(item) {
      return this.uselessTempList.some((itemU) => itemU.appid === item.appid)
    },
    handlePageChange(page) {
      this.page = page
      this.initData()
    },
    getCopyLongUrl(val, text) {
      const input = document.querySelector('#custom-input') // 获取隐藏input的dom
      this.inputVal = text
      this.$nextTick(() => {
        input.select() // 选中文本
        document.execCommand('Copy') // 执行浏览器复制命令
      })
    },
    addMini() {
      this.isEdit = false
      this.appids = []
      this.isShowDialog = true
    },
    editMini(val) {
      this.id = val.id
      this.isEdit = true
      this.remark = val.remark
      this.type = val.type
      this.subscribeUrl = val.subscribeUrl
      this.adminUserId = val.adminUser.id
      this.appids = val.appids.split(',')
      if (this.type === 1) {
        this.webUrl = val.webUrl
      } else {
        this.imgUrl = val.webUrl
      }
      this.$set(this.newParams, 'adminUserId', val.adminUser.id)
      this.isOpen = val.isOpen || false
      this.isShowDialog = true
    },
    delMini(id) {
      this.$confirm('确定要删除这条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteMiniProgram(id).then(() => {
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success',
          })
          this.initData()
        })
      })
    },
    delSelct() {
      const ids = this.selection.map((item) => item.id).join(',')
      this.$confirm('确定要批量删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteMiniProgram(ids).then(() => {
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success',
          })
          this.initData()
        })
      })
    },
    confirm() {
      if (this.type === 1 && !this.webUrl) {
        return this.$message.error('请填写文章链接')
      }
      if (this.type === 2 && !this.imgUrl) {
        return this.$message.error('请上传跳转图片')
      }
      // 清除失效小程序
      this.uselessTempList.forEach((item) => {
        const tmpIndex = this.appids.findIndex((appid) => {
          return appid === item.appid
        })
        if (tmpIndex !== -1) {
          this.appids.splice(tmpIndex, 1)
        }
      })
      if (this.appids.length === 0) {
        return this.$message.error('请选择跳转小程序')
      }
      if (!this.remark) {
        return this.$message.error('请输入公众号名称')
      }
      if (this.type === 2 && !this.subscribeUrl) {
        return this.$message.error('跳转图片模式必须填写关注链接')
      }
      if (this.isEdit) {
        editMiniProgram(this.id, {
          userId: this.adminUserId,
          webUrl: this.type === 1 ? this.webUrl : this.imgUrl,
          type: this.type,
          subscribeUrl: this.subscribeUrl,
          appids: this.appids.join(','),
          remark: this.remark,
          isOpen: this.isOpen,
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success',
            })
            this.initData()
          })
          .finally(() => {
            this.handleClose()
          })
      } else {
        updateMiniProgram({
          webUrl: this.type === 1 ? this.webUrl : this.imgUrl,
          type: this.type,
          subscribeUrl: this.subscribeUrl,
          appids: this.appids.join(','),
          remark: this.remark,
          isOpen: this.isOpen,
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: '新增成功!',
              type: 'success',
            })
            this.initData()
          })
          .finally(() => {
            this.handleClose()
          })
      }
    },
    handleSelectChange(val) {
      this.selection = val
    },
    handleClose() {
      this.isShowDialog = false
      this.isEdit = false
      this.appids = []
      this.subscribeUrl = null
      this.imgUrl = null
      this.type = 1
      this.newParams = {}
      this.webUrl = ''
      this.remark = ''
      this.id = null
    },
  },
  components: {
    SelectDeptUser,
    uploadImage,
  },
}
</script>
<style lang="scss" scoped>
a {
  color: #3f80ff;
}

.wx-link {
  background: #fff;

  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  > span {
    width: 100px;
  }
}

.button-list {
  text-align: center;
}

.link-tips {
  h6,
  p {
    font-size: 14px;
    line-height: 20px;
  }

  h6 {
    line-height: 25px;
  }

  p {
    text-indent: 14px;
  }
}

.title-box {
  p {
    height: 20px;
    line-height: 20px;

    & ~ p {
      margin-top: 1px;
    }
  }
}

.mini-check {
  line-height: 21px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.line_thr {
  text-decoration: line-through !important;
}
</style>
