<template>
  <div class="self-upload">
    <input
      ref="tyAvatar"
      style="display: none"
      type="file"
      multiple
      @change="fileChange"
      name="file"
      accept="image/*"
      class=""
    />
    <!-- <span class='replace-inputFileStyle'>点击上传图片</span> -->
    <div class="avatar-add" @click="handleAddAvatar" v-if="!imgUrl">
      <i class="el-icon-plus"></i>
    </div>
    <img
      @click="handleAddAvatar"
      :src="imgUrl"
      class="avatarImg"
      v-if="imgUrl"
    />
  </div>
</template>

<script>
import { uploadFile } from "../api/common";

export default {
  name: "uploadImage",
  model: {
    prop: "url",
    event: "uploadSuccess",
  },
  data() {
    return {
      loading: false,
      files: null,
      imgUrl: null,
    };
  },
  props: {
    width: {
      type: String,
      default: "120",
    },
    url: {
      default: "",
      type: String,
    },
    height: {
      type: String,
      default: "120",
    },
  },

  computed: {
    uploadSize() {
      return { width: `${this.width}px`, height: `${this.height}px` };
    },
  },
  methods: {
    handleAddAvatar() {
      this.$refs.tyAvatar.click();
    },
    delFile() {
      this.$emit("uploadSuccess", "");
      this.$refs.tyAvatar.value = null;
    },
    fileChange(e) {
      let targetFile = e.target.files[0];
      uploadFile({ file: targetFile })
        .then((res) => {
          this.imgUrl = res;
          this.$emit("uploadSuccess", res);
        })
        .finally(() => {
          this.$refs.tyAvatar.value = null;
          this.loading = false;
        });
    },
  },
  created() {
    this.imgUrl = this.url;
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.avatar-add {
  width: 100px;
  height: 100px;
  border: 1px dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.avatarImg {
  width: 100px;
  height: 100px;
}
</style>
